import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 960.000000 960.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,960.000000) scale(0.100000,-0.100000)" >
				<path d="M3214 6772 c-102 -36 -204 -132 -264 -252 -62 -123 -59 -53 -60
-1461 -1 -1361 -2 -1325 46 -1436 62 -143 161 -241 281 -282 103 -35 109 -34
598 94 72 18 299 77 505 129 1740 441 1619 408 1746 481 87 50 191 154 226
225 15 31 31 89 37 133 13 95 15 1223 2 1312 -26 175 -172 329 -391 412 -71
27 -104 36 -730 194 -239 61 -529 134 -645 164 -115 30 -327 84 -470 120 -270
69 -556 142 -660 169 -77 21 -157 20 -221 -2z m1806 -717 c105 -18 227 -50
300 -76 75 -28 190 -81 190 -88 4 -198 3 -421 -2 -421 -4 0 -33 12 -65 26
-270 122 -487 173 -687 161 -119 -7 -198 -26 -284 -68 -73 -36 -167 -119 -210
-187 -120 -187 -118 -517 4 -701 74 -111 178 -188 309 -227 95 -29 296 -25
395 7 l75 25 3 127 3 126 -133 3 -133 3 0 180 0 180 385 0 385 0 3 -395 c1
-217 0 -404 -3 -415 -2 -11 -22 -31 -42 -45 -263 -173 -509 -240 -838 -227
-301 11 -523 99 -710 282 -107 105 -126 130 -181 235 -138 266 -158 622 -49
895 16 39 34 80 42 92 14 21 16 21 51 6 47 -20 163 -13 212 13 55 28 98 73
126 131 24 48 26 62 22 131 -3 42 -8 84 -12 93 -13 30 173 101 352 133 106 20
384 20 492 1z"/>
<path d="M5168 6503 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5128 6493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5770 6300 c8 -5 22 -10 30 -10 13 0 13 1 0 10 -8 5 -22 10 -30 10
-13 0 -13 -1 0 -10z"/>
<path d="M6018 6233 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6643 6200 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M6666 6043 c2 -137 -1 -203 -8 -203 -7 0 -8 26 -3 80 5 61 4 80 -5
80 -10 0 -12 -27 -8 -117 5 -111 5 -115 -8 -68 l-13 50 2 -50 c1 -39 -2 -51
-15 -53 -15 -3 -15 1 -5 28 10 27 10 33 -6 41 -9 6 -17 20 -16 32 0 21 1 21 9
2 8 -16 9 -13 7 14 -1 23 -7 35 -18 36 -12 2 -15 -3 -11 -21 4 -20 0 -24 -20
-24 -13 0 -29 5 -36 12 -9 9 -12 9 -12 0 0 -7 5 -12 10 -12 6 0 10 -4 10 -8 0
-13 -30 -10 -31 2 -1 13 -17 -24 -18 -41 -1 -7 6 -13 15 -13 12 0 14 -5 8 -17
-4 -10 -9 -39 -12 -65 -2 -27 -8 -48 -13 -48 -4 0 -10 -300 -11 -672 -4 -751
-2 -733 -74 -846 -107 -167 -282 -263 -629 -346 -304 -73 -728 -185 -715 -189
16 -6 65 -18 450 -116 129 -33 352 -89 495 -126 227 -58 268 -66 320 -61 177
17 339 184 390 400 22 94 22 1956 0 1956 -13 0 -15 33 -16 233 0 127 -4 255
-8 282 -4 28 -6 -39 -5 -152z m-140 -259 c-3 -8 -2 -12 4 -9 6 3 10 1 10 -4 0
-6 -9 -11 -19 -11 -14 0 -18 5 -14 20 3 11 10 20 15 20 6 0 7 -7 4 -16z m141
-166 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z"/>
<path d="M6240 6120 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6300 6065 c8 -10 24 -28 35 -39 11 -12 30 -34 42 -51 14 -19 22 -24
23 -15 0 9 -9 22 -20 30 -11 8 -20 19 -20 25 0 11 -32 43 -60 60 -13 7 -13 6
0 -10z"/>
<path d="M6345 6070 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M6693 6040 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M6392 6030 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M6562 5955 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M6408 5949 c-2 -7 5 -37 15 -68 11 -31 26 -83 33 -116 l13 -60 -4 67
c-2 38 -9 77 -15 88 -12 22 -5 60 11 60 5 0 9 -9 9 -20 0 -11 4 -20 9 -20 5 0
11 9 14 20 3 11 1 20 -4 20 -5 0 -9 9 -9 20 0 16 -7 20 -34 20 -19 0 -36 -5
-38 -11z m32 -39 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z"/>
<path d="M6693 5880 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3421 3123 c-129 -26 -137 -214 -11 -250 34 -10 57 -11 96 -3 52 11
52 11 56 53 7 68 2 77 -48 77 -37 0 -44 -3 -44 -20 0 -13 7 -20 19 -20 15 0
19 -5 16 -21 -5 -26 -6 -26 -25 -29 -64 -9 -100 20 -100 81 0 55 14 76 56 88
27 7 47 6 76 -4 34 -11 40 -11 45 2 3 8 -1 21 -8 28 -16 15 -91 26 -128 18z"/>
<path d="M4633 3123 c-28 -5 -53 -39 -53 -71 0 -30 48 -76 92 -88 23 -7 34
-16 36 -33 3 -22 1 -23 -40 -19 -23 3 -51 8 -60 12 -15 7 -18 3 -18 -18 0 -20
6 -27 28 -32 15 -3 33 -8 41 -10 24 -7 90 16 101 36 16 29 12 75 -7 92 -10 9
-39 25 -65 36 -61 27 -65 52 -7 52 22 0 49 -4 59 -10 23 -12 37 17 18 37 -15
14 -90 24 -125 16z"/>
<path d="M5534 3120 c-43 -13 -82 -56 -90 -96 -19 -104 71 -178 188 -153 41 9
48 14 48 35 0 24 -23 33 -35 14 -10 -16 -102 -12 -119 6 -24 23 -30 61 -17
104 9 32 17 39 49 47 27 7 51 6 80 -3 41 -12 42 -11 42 11 0 13 -4 26 -8 29
-18 11 -109 15 -138 6z"/>
<path d="M5800 3124 c-71 -26 -105 -74 -98 -138 7 -59 32 -92 84 -112 41 -15
50 -16 92 -3 58 19 92 63 92 123 0 45 -27 99 -58 115 -24 13 -92 22 -112 15z
m90 -64 c37 -37 24 -121 -23 -143 -57 -28 -106 8 -107 81 0 53 26 82 75 82 22
0 43 -8 55 -20z"/>
<path d="M3610 2990 l0 -131 88 3 c79 3 87 5 87 23 0 17 -8 20 -57 23 l-58 3
0 34 c0 34 0 34 53 37 38 2 52 7 52 18 0 11 -14 16 -52 18 -52 3 -53 4 -53 33
l0 30 107 -3 107 -3 1 -105 0 -105 30 0 30 0 3 107 3 108 39 -2 c45 -1 55 4
47 27 -5 12 -27 15 -122 15 -103 0 -115 -2 -115 -17 -1 -17 -1 -17 -14 0 -11
14 -29 17 -95 17 l-81 0 0 -130z"/>
<path d="M4062 2993 l3 -128 25 0 c24 0 25 2 28 78 2 42 7 77 12 77 6 0 10 -3
10 -7 0 -5 14 -24 31 -43 l31 -35 29 35 c15 19 29 38 29 43 0 4 5 7 10 7 6 0
10 -34 10 -81 l0 -80 28 3 27 3 3 128 3 127 -30 0 c-22 0 -38 -11 -70 -47
l-41 -48 -41 48 c-32 36 -48 47 -70 47 l-30 0 3 -127z"/>
<path d="M4382 2993 l3 -128 88 -3 87 -3 0 26 c0 24 -2 25 -61 25 l-60 0 4 33
c3 31 5 32 55 35 43 3 52 6 52 22 0 16 -9 19 -52 22 -49 3 -53 5 -53 28 0 23
4 25 58 28 49 3 57 6 57 22 0 18 -7 20 -90 20 l-91 0 3 -127z"/>
<path d="M4820 3026 c0 -79 3 -97 21 -119 27 -35 87 -51 139 -37 59 16 73 45
78 156 l4 94 -31 0 -31 0 0 -88 c0 -98 -14 -124 -65 -124 -47 0 -55 16 -55
118 l0 94 -30 0 -30 0 0 -94z"/>
<path d="M5102 2993 l3 -128 82 0 c65 1 86 4 102 19 26 24 28 82 4 108 -14 16
-15 23 -5 38 16 27 -1 68 -33 80 -15 6 -55 10 -91 10 l-65 0 3 -127z m123 73
c16 -12 17 -16 6 -30 -17 -20 -60 -21 -67 -1 -15 39 23 59 61 31z m9 -92 c10
-4 16 -18 16 -35 0 -28 -2 -29 -45 -29 -43 0 -45 1 -45 28 0 16 3 32 7 35 8 9
46 9 67 1z"/>
<path d="M6010 2990 l0 -130 30 0 30 0 0 83 1 82 38 -45 c22 -25 41 -43 43
-40 27 35 62 75 66 75 2 0 5 -34 6 -75 1 -74 1 -75 29 -78 l27 -3 0 130 0 131
-24 0 c-17 0 -36 -14 -62 -45 -20 -25 -42 -45 -48 -45 -7 0 -27 20 -46 45 -26
35 -40 45 -62 45 l-28 0 0 -130z"/>
<path d="M5357 2914 c-4 -4 -7 -16 -7 -26 0 -13 7 -18 26 -18 21 0 25 4 22 22
-3 23 -27 35 -41 22z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
